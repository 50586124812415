import React from 'react';
import PropTypes from 'prop-types';
import styles from './LockedUserPopup.module.scss';
import fetch from 'node-fetch';
import moment from 'moment';
import authenticator from 'authenticator';
import { confirmAlert } from 'react-confirm-alert'; // Import


import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { SketchPicker } from 'react-color'

import { FaExclamationTriangle, FaLock, FaFemale, FaMale, FaUsers, FaLightbulb } from "react-icons/fa";

import {
  BrowserRouter as Router,
  Switch as SwitchRouter,
  Route,
  Link,
  Redirect,
  withRouter,
  useParams,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

import Switch from "@material-ui/core/Switch";

class CreateUserPopup extends React.Component {

    state = {
      "users": [],
      "banned": [],
      "wrong_password": [],
      "expied_users": [],
      "sim_server_status": [],
      "filter_by": null,
    }

    refresh() {
    }
    componentWillReceiveProps() {
    }
    componentWillMount() {
    	this.update_data(this.props);
      var intervalId = setInterval(this.update_data_live.bind(this, this.props), 3000)
      
      this.setState({ intervalId: intervalId })
    }

    fromNow(date) {
      // return moment(date).relativeTimeThreshold('m', 60);
      return (moment(date)).fromNow();
      clearInterval(this.state.intervalId)
    }

    updateSimCid(user) {
      var token = localStorage.getItem('user_token');
      
      fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/update-sim-cid?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(user)
      })
      .then(response => response.json())
      .then(data => {
          NotificationManager.success('success', 'update sim cid success');
      })
      .catch(err => {
          NotificationManager.error('error', 'update sim cid success');
      });

    }

    update_data(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-locked-profiles?access_token=${token}&id=${this.props.match.params.profile_id}`, {})
        .then(response => response.json())
        .then(data => {

          data.sort((a, b) => {
            return a.sim_id - b.sim_id;
          })

        	this.setState({
		    	 "users": data,
        	});
        })
        .catch(err => {

        });
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-banned-profiles?access_token=${token}&id=${this.props.match.params.profile_id}`, {})
        .then(response => response.json())
        .then(data => {

          data.sort((a, b) => {
            return a.sim_id - b.sim_id;
          })


          this.setState({
           "banned": data,
          });
        })
        .catch(err => {

        });
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-invalid-password-profiles?access_token=${token}&id=${this.props.match.params.profile_id}`, {})
        .then(response => response.json())
        .then(data => {

          data.sort((a, b) => {
            return a.sim_id - b.sim_id;
          })


          this.setState({
           "wrong_password": data,
          });
        })
        .catch(err => {

        });

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-expied-accounts-profiles?access_token=${token}&id=${this.props.match.params.profile_id}`, {})
        .then(response => response.json())
        .then(data => {

          data.sort((a, b) => {
            return a.sim_id - b.sim_id;
          })


          this.setState({
           "expied_users": data,
          });
        })
        .catch(err => {

        });
    }

    update_data_live(props) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/sim-server-status?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
           "sim_server_status": data,
          });
        })
        .catch(err => {

        });
    }

    getSlot(user) {
      return this.state.sim_server_status.find((a) => a.iccid == user.sim_cid)
    }
    activatePort(user, port) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/activate-sim-port?access_token=${token}&port=${port}`, {})
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('success', 'update sim port success, please wait');
        })
        .catch(err => {
          NotificationManager.error('error', 'cant activate sim port');
        });
    }

    unlockUser(user, port) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/unlock-facebook-user?access_token=${token}&port=${port}&user=${user._id}`, {})
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('success', 'update sim port success, please wait');
        })
        .catch(err => {
          NotificationManager.error('error', 'cant activate sim port');
        });
    }

    resetUser(user, port) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/reset-facebook-user?access_token=${token}&port=${port}&user=${user._id}`, {})
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('success', 'update sim port success, please wait');
        })
        .catch(err => {
          NotificationManager.error('error', 'cant activate sim port');
        });
    }

    runRow(channel) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/run-channel?access_token=${token}&channel=${channel}`, {})
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('success', 'added row to que');
        })
        .catch(err => {
          NotificationManager.error('error', 'cant add row to que');
        });
    }

    unlockUserWithBirthday(user, port) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/unlock-facebook-user-birthday?access_token=${token}&port=${port}&user=${user._id}`, {})
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('success', 'update sim port success, please wait');
        })
        .catch(err => {
          NotificationManager.error('error', 'cant activate sim port');
        });
    }

    channels = [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
      '32',
    ]

    channel_cells = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
    ]

    handleUnlock(profile_id) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/unlock?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: profile_id,
          })
        })
        .then(response => response.json())
        .then(data => {

          NotificationManager.success('Unlocked');
        })
        .catch(err => {
          NotificationManager.error('Error unlocking');
        })

    }
    handleSwitchBanned(profile_id) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/switch-banned-profile?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: profile_id,
          })
        })
        .then(response => response.json())
        .then(data => {

          NotificationManager.success('Unlocked');
        })
        .catch(err => {
          NotificationManager.error('Error unlocking');
        })

    }
    handleUnbane(profile_id) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/switch-unbanne?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: profile_id,
          })
        })
        .then(response => response.json())
        .then(data => {

          NotificationManager.success('Unlocked');
        })
        .catch(err => {
          NotificationManager.error('Error unlocking');
        })

    }
    switchPassword(profile_id) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/switch-password-profile?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: profile_id,
          })
        })
        .then(response => response.json())
        .then(data => {

          NotificationManager.success('switch Password');
        })
        .catch(err => {
          NotificationManager.error('Error switch Password');
        })

    }
    handleBanned(profile_id) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/mark-banned?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: profile_id,
          })
        })
        .then(response => response.json())
        .then(data => {

          NotificationManager.success('Banned');
        })
        .catch(err => {
          NotificationManager.error('Error banning');
        })

    }

    render() {
    	const { t, i18n } = this.props;

        return (
		  <div className="overlay">
        <style>{'body {overflow: hidden;}'}</style>

              <div style={{width: '100%'}} className="bg-primary p-5 text-white text-center rounded-3 " id="myForm">

                  <table className="table">
                    <tr style={{
                      background: '#959191',
                      color: '#fff',
                    }}>
                      {this.channels.map((channel) => (<td>
                        <div>
                          <strong>{channel}</strong>
                        </div>
                        <div>
                          <button onClick={this.runRow.bind(this, channel)}>Run Row {channel}</button>
                        </div>
                      </td>))}
                    </tr>
                    {this.channel_cells.map((cell) => (<tr>
                      {this.channels.map((channel) => (<td>
                        {this.state.sim_server_status[0] && [this.state.sim_server_status.find((p) => p.port === `${channel}.${cell}`)].map( (pport) => (<React.Fragment>
                          <div>
                            {!pport ? <FaLightbulb style={{
                              color: '#000',
                              fontSize: '30px',
                              lineHeight: '41px',
                            }}/> : ''}
                          </div>

                          <div>
                          {pport ? !pport.active && <FaLightbulb style={{
                            color: 'rgb(248 147 147)',
                            fontSize: '30px',
                            lineHeight: '41px',
                          }}/> : ''}
                          </div>

                          <div>
                          {pport ? pport.active && <FaLightbulb style={{
                            color: '#3fff3f',
                            fontSize: '30px',
                            lineHeight: '41px',
                          }}/> : ''}
                          </div>
                          

                          {pport && [this.state.users.concat(this.state.wrong_password).find((p) => p.sim_cid === pport.iccid)].map( (user) => (<React.Fragment>
                            {user && <React.Fragment>
                              <img width="40" src={`https://graph.facebook.com/${user.facebook_id}/picture?type=large&access_token=EAADNABdFZBjsBAFb9y1k7uHELcvZCj6rNxoMdUHZCXt4swNSTMPrNVvX83xmG8W1PavLelMGVxAcgJv3g5IAJRyeiTRPdsPYQJqer7hJxU5bZBoPnlI4kCgkqqJ9sBg6WZANdhZAAGVmHFCAlw5zEQ8A9QfJYDzocbqfmJ7r0hGqRCVpy1wDYZB3L7GicZBVkss1i5tlWhZApZBPjmemGkKqQ7wtOfMdKmZAt4Es7RZCyNO9ZCBSSJ8nZAtVcIJVRH4t3uj2ZCgiYtrZBr09KWWYopAVnjTk`}/>
                              
                              <span>{user.block_status && user.block_status.sent_profile_picture ? <button onClick={this.resetUser.bind(this, user, pport.port)} style={{background: 'red'}}>Sent - reset</button> : (pport.active ? <button onClick={this.unlockUser.bind(this, user, pport.port)}>Unlock</button> : <button onClick={this.activatePort.bind(this, user, pport.port)}>Activate Sim</button>)}</span>

                              {user.first_name} - <strong style={{color: 'red'}}>{user.sim_id}</strong>
                              <strong>-{user.login_try_count}-</strong>

                            </React.Fragment>}
                          </React.Fragment>) )}


                          {pport && pport.port}
                        </React.Fragment>) )}
                      </td>))}
                    </tr>))}
                  </table>

                  <div className="pb-5 position-relative" id="signin_header">
                    <h2 className="fs-5 fw-bolder text-uppercase text-center">{this.props.edit ? t('Edit profile') : t('Locked Profiles')}</h2>
                    <a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console']})}>×</a>
                  </div>

                  <div className="btn-group small-btn" role="group" aria-label="Basic example">
                    <button type="button" className={`btn btn-secondary`} onClick={() => { this.setState({filter_by: ''}) }}>All</button>
                    <button type="button" className={`btn btn-secondary`} onClick={() => { this.setState({filter_by: 'Picture Not Sent'}) }}>Picture Not Sent</button>
                    <button type="button" className={`btn btn-secondary`} onClick={() => { this.setState({filter_by: 'open'}) }}>Open</button>
                  </div>

                  <h4>Blocked</h4>
                  <div class="row">
                    <div class="col-12">
                      <ul className="list-group" style={{color: "black"}}>
                        {this.state.users.filter((u) => {
                          if (!this.state.filter_by) {
                            return true;
                          }

                          if (this.state.filter_by === 'locked' && u.locked_account) {
                            return true;
                          }
                          if (this.state.filter_by === 'Picture Not Sent' && ( (!u.block_status) || u.block_status && (!u.block_status.sent_profile_picture) ) ) {
                            return true;
                          }
                          if (this.state.filter_by === 'open' && !u.locked_account) {
                            return true;
                          }

                        }).map((user, index) => <div>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <strong>{index}:</strong>
                            <strong>{user.account.username}</strong>
                            {user.first_name} {user.last_name}
                            <span className="badge badge-primary badge-pill badge-blue">{user.birth_day}</span>
                            <span className="badge badge-primary badge-pill badge-blue">sim_id_{user.sim_id}</span>
                            <span className={`badge badge-pill ${!user.locked_account ? 'badge-success' : 'badge-danger'}`}>{user.locked_account && 'locked'} {!user.locked_account && 'Open'}</span>

                            <img width="40" src={`https://graph.facebook.com/${user.facebook_id}/picture?type=large&access_token=EAADNABdFZBjsBAFb9y1k7uHELcvZCj6rNxoMdUHZCXt4swNSTMPrNVvX83xmG8W1PavLelMGVxAcgJv3g5IAJRyeiTRPdsPYQJqer7hJxU5bZBoPnlI4kCgkqqJ9sBg6WZANdhZAAGVmHFCAlw5zEQ8A9QfJYDzocbqfmJ7r0hGqRCVpy1wDYZB3L7GicZBVkss1i5tlWhZApZBPjmemGkKqQ7wtOfMdKmZAt4Es7RZCyNO9ZCBSSJ8nZAtVcIJVRH4t3uj2ZCgiYtrZBr09KWWYopAVnjTk`}/>

                            <button onClick={this.handleUnlock.bind(this, user._id)}>Unlock</button>
                            <button onClick={this.handleBanned.bind(this, user._id)}>Banned</button>

                            <span>{user.block_status ? <span>{user.block_status.sent_profile_picture} <img width="60px" src={`https://images.fbzipper.com/api/users/profile/${user._id}?f=100080894850327`} g="img/user1.png" alt=""/></span> : 'NA'}</span>
                            <span>{user.block_status && user.block_status.banned_forever ? 'GGGGG' : ''}</span>

                            <span><input type="text" value={user.sim_cid} onChange={e => {
                              user.sim_cid = e.target.value;
                              this.setState({
                                users: this.state.users
                              });
                            }}/><button onClick={this.updateSimCid.bind(this, user)}>Save</button></span>
                          
                            {this.getSlot(user) && <span>
                              {<FaLightbulb style={{
                                color: this.getSlot(user).active ? '#3ee724' : '#ff6b00',
                                fontSize: '24px',
                                lineHeight: '41px',
                              }}/>}

                              {this.getSlot(user).port}

                              {this.getSlot(user).active ? <button onClick={this.unlockUser.bind(this, user, this.getSlot(user).port)}>Unlock</button> : <button onClick={this.activatePort.bind(this, user, this.getSlot(user).port)}>Activate Sim</button>}
                            </span>}
                            {!this.getSlot(user) && <button onClick={this.unlockUserWithBirthday.bind(this, user)}>Unlock With Birthday</button>}
                          </li>
                          {this.getSlot(user) && this.getSlot(user).sms_messages.map((m) => (<div>{m.message} http://localhost:8888/get_sms/{user._id}</div>))}
                        </div>)}
                      </ul>
                    </div>
                  </div>

                  <h4>Banned</h4>
                  <div class="row">
                    <div class="col-12">
                      <ul className="list-group" style={{color: "black"}}>
                        {this.state.banned.map((user, index) => <div>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <strong>{index}:</strong>
                            <strong>{user.account.username}</strong>
                            {user.first_name} {user.last_name}

                            <img width="40" src={`https://graph.facebook.com/${user.facebook_id}/picture?type=large&access_token=EAADNABdFZBjsBAFb9y1k7uHELcvZCj6rNxoMdUHZCXt4swNSTMPrNVvX83xmG8W1PavLelMGVxAcgJv3g5IAJRyeiTRPdsPYQJqer7hJxU5bZBoPnlI4kCgkqqJ9sBg6WZANdhZAAGVmHFCAlw5zEQ8A9QfJYDzocbqfmJ7r0hGqRCVpy1wDYZB3L7GicZBVkss1i5tlWhZApZBPjmemGkKqQ7wtOfMdKmZAt4Es7RZCyNO9ZCBSSJ8nZAtVcIJVRH4t3uj2ZCgiYtrZBr09KWWYopAVnjTk`}/>

                            <button onClick={this.handleUnbane.bind(this, user._id)}>Unbane</button>
                            
                            <button onClick={this.handleSwitchBanned.bind(this, user._id)}>Switch Banned</button>
                          </li>
                        </div>)}
                      </ul>
                    </div>
                  </div>


                  <h4>wrong password</h4>
                  <div class="row">
                    <div class="col-12">
                      <ul className="list-group" style={{color: "black"}}>
                        {this.state.wrong_password.map((user, index) => <div>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <strong>{index}:</strong>
                            <strong>{user.account.username}</strong>
                            {user.first_name} {user.last_name}

                            <span className="badge badge-primary badge-pill badge-blue">sim_id_{user.sim_id}</span>

                            <img width="40" src={`https://graph.facebook.com/${user.facebook_id}/picture?type=large&access_token=EAADNABdFZBjsBAFb9y1k7uHELcvZCj6rNxoMdUHZCXt4swNSTMPrNVvX83xmG8W1PavLelMGVxAcgJv3g5IAJRyeiTRPdsPYQJqer7hJxU5bZBoPnlI4kCgkqqJ9sBg6WZANdhZAAGVmHFCAlw5zEQ8A9QfJYDzocbqfmJ7r0hGqRCVpy1wDYZB3L7GicZBVkss1i5tlWhZApZBPjmemGkKqQ7wtOfMdKmZAt4Es7RZCyNO9ZCBSSJ8nZAtVcIJVRH4t3uj2ZCgiYtrZBr09KWWYopAVnjTk`}/>

                            <button onClick={this.handleUnlock.bind(this, user._id)}>Unlock</button>

                            <button onClick={this.switchPassword.bind(this, user._id)}>Switch Password</button>
                          </li>
                        </div>)}
                      </ul>
                    </div>
                  </div>


                  <h4>Expied Users</h4>
                  <div class="row">
                    <div class="col-12">
                      <ul className="list-group" style={{color: "black"}}>
                        {this.state.expied_users.map((user, index) => <div>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <strong>{index}:</strong>
                            <strong>{user.account.username}</strong>
                            {user.first_name} {user.last_name}

                            <span className="badge badge-primary badge-pill badge-blue">sim_id_{user.sim_id}</span>

                            <img width="40" src={`https://graph.facebook.com/${user.facebook_id}/picture?type=large&access_token=EAADNABdFZBjsBAFb9y1k7uHELcvZCj6rNxoMdUHZCXt4swNSTMPrNVvX83xmG8W1PavLelMGVxAcgJv3g5IAJRyeiTRPdsPYQJqer7hJxU5bZBoPnlI4kCgkqqJ9sBg6WZANdhZAAGVmHFCAlw5zEQ8A9QfJYDzocbqfmJ7r0hGqRCVpy1wDYZB3L7GicZBVkss1i5tlWhZApZBPjmemGkKqQ7wtOfMdKmZAt4Es7RZCyNO9ZCBSSJ8nZAtVcIJVRH4t3uj2ZCgiYtrZBr09KWWYopAVnjTk`}/>

                            <button onClick={this.handleUnlock.bind(this, user._id)}>Unlock</button>

                            <button onClick={this.switchPassword.bind(this, user._id)}>Switch Password</button>
                          </li>
                        </div>)}
                      </ul>
                    </div>
                  </div>
              </div>

		  </div>
        );
    }
}

CreateUserPopup.propTypes = {};

CreateUserPopup.defaultProps = {};

export default withRouter(CreateUserPopup);
















